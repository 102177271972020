.news-list-div{
    height: fit-content;
    width: fit-content;
}

.news-list{
    height: calc(100vh - 130px - 43px);
    overflow-y: auto;
    overflow-x: hidden;
}

.news-list::-webkit-scrollbar{
    display: none;
}

.news-list-h2{
    font-size: 24px;
    margin: 0;
    margin-top: 15px;
    font-style: normal;
    font-weight: bold;
    font-family: Serif;
    color: #2c507b;
}

.news-item-div{
    width: 20vw;
    font-style: normal;
    font-family: Serif;
    color: #2c507b;
    border-bottom: 1px solid #2c507bd5;
}

.news-item-name{
    padding: 10px 0 0 0;
}

@media (max-width: 600px){
    .news-list-div{
        display: none;
    }
}