.custom-modal-div{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    background-color: rgba(68, 66, 66, 0.5);
}

.custom-modal-content{
    width: fit-content;
    background-color: white;
    border-radius: 15px;
    padding: 15px;
}

.custom-modal-content img{
    width: 60vw;
    border-radius: 15px;
}