.header-div{
    position: absolute;
    top: 20px;
    left: 6vw;
    z-index: 15;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    width: calc(88vw - 20px);
    border-radius: 15px;
    height: 60px;
    background-color: rgb(241, 241, 236);
}

.logo-div{
    display: flex;
    align-items: center;
    cursor: pointer;
    max-width: 50%;
}

.logo-image{
    width: 60px;
    height: 60px;
    margin-right: 10px;
    margin-left: 15px;
}

.name-div{
    display: block;
    text-align: center;
    align-self: center;
}

.name-h{
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-family: Serif;
    color: #2c507b;
}

.search-lang-end{
    display: flex;
    align-items: end;
}

.search-lang-div{
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 600px){
    .header-div{
        height: 80px;
    }

    .logo-image{
        min-width: 50px;
        max-width: 50px;
        height: 50px;
    }

    .name-div{
        display: none;
    }
}
