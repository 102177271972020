.images-library-main{
    display: flex;
}

.images-library-div{
    width: 58vw;
    margin-right: 2vw;
}

.images-library-category{
    width: fit-content;
    padding-right: 30px;
    color: #2c507b;
    font-size: 20px;
    border-bottom: 1px solid #2c507b;
}

.images-div{
    display: flex;
    flex-wrap: wrap;
}

.image-descr-div{
    display: flex;
    width: calc((58vw - 80px)/4);
    margin: 10px;
    transform: scale(1);
    box-shadow: none;
    transition: transform ease-in 0.3s,box-shadow ease-in 0.3s
}

.image-descr-div:hover{
    transform: scale(1.05);
    box-shadow: 5px 5px 5px rgba(168, 167, 167, 0.692);
    transition: transform ease-in 0.3s, box-shadow ease-in 0.3sbox-shadow ease-in 0.3s;
}

.image-descr-div img{
    width: calc((58vw - 80px)/4);
}

.image-descr-p{
    width: 100%;
    height: 100%;
    position: absolute;
    color: white;
    font-size: 18px;
    opacity: 0;
    transition: opacity ease-in 0.3s;
}

.image-descr-p:hover{
    background: rgba(68, 66, 66, 0.5);
    opacity: 1;
    transition: opacity ease-in 0.3s;
}

.image-descr-p p{
    margin: 10px;
}
