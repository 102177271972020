.products-main{
    display: flex;
}

.products-size{
    width: 60vw;
}

.products-list{
    width: 58vw;
    padding: 1vw;
    display: flex;
    flex-wrap: wrap;
}

.products-category{
    width: fit-content;
    padding-right: 30px;
    color: #2c507b;
    font-size: 20px;
    border-bottom: 1px solid #2c507b;
    margin: 15px 1vw;
}

.products-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc((60vw - 5vw - 25px)/3);
    margin-right: 1vw;
    margin-bottom: 1vw;
    padding: 5px 0;
    background-color: white;
    border: 1px solid rgba(168, 167, 167, 0.692);
    box-shadow: 5px 5px 5px rgba(168, 167, 167, 0.692);
    border-radius: 20px;
    cursor: pointer;
    transform: scale(1);
    transition: transform ease-in 0.2s;
}

.products-item:hover{
    transform: scale(1.03);
    transition: transform ease-in 0.2s;
}

.products-image{
    width: calc((60vw - 8vw)/3);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.products-title{
    font-size: 16px;
    font-weight: bolder;
    margin: 10px 0
}

.products-date{
    display: flex;
    align-items: center;
    font-size: 14px;
}

.products-date p {
    margin: 0;
    margin-left: 10px
}

.products-page-main{
    width: 80vw;
    display: flex;
}

.products-page-info{
    width: 60vw;
}

.products-page-image{
    width: 58vw;
    margin-right: 1vw;
}

.products-page-created{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.products-page-created p {
    margin: 0;
    margin-left: 10px;
}