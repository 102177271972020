.div-centre{
    width: 100vw;
    display: flex;
    justify-content: center;
    padding: 20px 0;
}

.div-centre-all{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1vw 0 1vw 0;
}

.images-div-scroll{
    width: calc(88vw + 8*1vw);
    overflow: hidden;
}

.images-div{
    display: flex;
    width: fit-content;
    animation: slide 32s infinite; 
    padding: 15px 0;
}

.images-div:hover{
    animation-play-state: paused;
}

.images-size{
    width: 22vw;
    margin: 0 1vw 0 1vw;
    transform: scale(1);
    box-shadow: none;
    transition: transform  ease-in 0.3s, box-shadow ease-in 0.3s
}

.images-size:hover{
    transform: scale(1.05);
    box-shadow: 5px 5px 5px rgba(168, 167, 167, 0.692);
    transition: transform ease-in 0.3s,box-shadow ease-in 0.3s
}

.line-div{
    width: 25vw;
    height: 1px;
    background-color: #2c507b;
}

@keyframes slide {
    0% {
        transform: translateX();
    }
    10% {
        transform: translateX(-24vw);
    }
    20% {
        transform: translateX(-48vw);
    }
    30% {
        transform: translateX(-72vw);
    }
    40% {
        transform: translateX(-96vw);
    }
    50% {
        transform: translateX(-120vw);
    }
    60% {
        transform: translateX(-144vw);
    }
    70% {
        transform: translateX(-168vw);
    }
    80% {
        transform: translateX(-192vw);
    }
    90% {
        transform: translateX(-216vw);
    }
    100% {
        transform: translateX(-240vw);
    }
}