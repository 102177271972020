.main{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.block-div{
    width: 40vw;
}

.reg-log-input{
    width: calc(100% - 10px);
    font-size: 14px;
    margin-bottom: 1vh;
    border: none;
    border-bottom: 1px solid #2c507b75;
    padding: 5px;
    outline: none;
}

.reg-log-input-with-eye{
    display: flex;
    align-items: center;
    width: calc(100% - 10px);
    margin-bottom: 1vh;
    border-bottom: 1px solid #2c507b75;
    padding: 5px;
}

.reg-log-input-with-eye input{
    width: 100%;
    border: none;
    outline: none;
}

.block-div button{
    width: 100%;
    margin-top: 1vh;
    font-size: 18px;
    background-color: #2c507b;
    color: white;
    border: none;
    padding: 10px 0;
    border-radius: 10px;
    cursor: pointer;
}

.error{
    width: 100%;
    text-align: center;
    color: rgba(221, 28, 28, 0.877);
    font-size: 14px;
    margin-top: 10px;
}
