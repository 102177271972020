.expo-menu{
    display: flex;
    width: 80vw;
}

.expo-title{
    width: calc(80vw/3);
    font-size: 18px;
    height: fit-content;
    text-align: center;
    border: 1px solid rgba(128, 128, 128, 0.692);
    cursor: pointer;
}

.choosed{
    background-color: #2c507b;
    color: white
}