.services-div{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}

.service-img{
    margin: 0.75vw;
    width: 26vw;
    transform: scale(1);
    box-shadow: none;
    transition: transform  ease-in 0.3s, box-shadow ease-in 0.3s
  }

.service-img:hover{
    transform: scale(1.02);
    box-shadow: 5px 5px 5px rgba(168, 167, 167, 0.692);
    transition: transform ease-in 0.3s, box-shadow ease-in 0.3s
}


@media (max-width: 600px){
    .service-img{
        margin: 2vw;
        width: 96vw;
    }
}

