.hole-expo{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.expo-soon-image-div{
    width: fit-content;
    height: fit-content;
    overflow: hidden;
}

.expo-soon-image{
    width: 100vw;
    animation: imageScale 2s;
}

@keyframes imageScale{
    0%{
        transform: scale(1)
    }
    10% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

.undermenues{
    width: 100vw;
    display: flex;
}

.menu-title{
    width: calc(100vw/3);
    text-align: center;
    color: #2c507b;
    font-size: 20px;
    font-weight: bolder;
    border-bottom: 1px solid gray ;
    border-right: 1px solid gray;
    cursor: pointer;
}

.menu-title:last-child{
    border-right: none;
}

.menu-title p{
    width: 100%;
    height: 100%;
    transform: scale(1);
    transition: transform ease-in 0.2s;
}

.menu-title p:hover{
    transform: scale(1.1);
    transition: transform ease-in 0.3s;
}

.choosed{
    background-color: #2c507b;
    color: white;
}

.main-expo-places{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-div{
    display: flex;
    justify-content: space-between;
    width: 60vw;
}

.contact-part-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px;
}

.contact-part-icon{
    font-size: 30px;
    color: #2c507b;
    margin-top: 20px;
}

.contact-part-title{
    font-size: 20px;
    font-weight: bold;
}

.contact-part-div a{
    padding: 16px 0;
    color: black;
}

.images-div{
    display: flex;
    flex-wrap: wrap;
    width: 80vw;
    margin-bottom: 15px;
}

.image-div{
    display: flex;
    width: calc((80vw - 80px)/4);
    margin: 10px;
    transform: scale(1);
    box-shadow: none;
    transition: transform ease-in 0.3s,box-shadow ease-in 0.3s
}

.image-div:hover{
    transform: scale(1.05);
    box-shadow: 5px 5px 5px rgba(168, 167, 167, 0.692);
    transition: transform ease-in 0.3s, box-shadow ease-in 0.3sbox-shadow ease-in 0.3s;
}

.image-div img{
    width: calc((80vw - 80px)/4);
}

.status-main{
    display: flex;
}

.status-size{
    width: 80vw;
}

.status-list{
    padding: 1vw;
    display: flex;
    flex-wrap: wrap;
}

.expo-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc((80vw - 7vw - 25px)/4);
    margin-right: 1vw;
    margin-bottom: 1vw;
    padding: 5px 0;
    background-color: white;
    border: 1px solid rgba(168, 167, 167, 0.692);
    box-shadow: 5px 5px 5px rgba(168, 167, 167, 0.692);
    border-radius: 20px;
    cursor: pointer;
    transform: scale(1);
    transition: transform ease-in 0.2s;
}

.expo-item:hover{
    transform: scale(1.03);
    transition: transform ease-in 0.2s;
}

.expo-item-image{
    width: calc((80vw - 11vw)/4);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.expo-title{
    font-size: 16px;
    font-weight: bolder;
    margin: 10px 0
}

.expo-page-main{
    width: 80vw;
    display: flex;
}


.expo-page-image{
    width: 80vw;
    margin-top: 15px;
}

.expo-page-created{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.expo-page-created p {
    margin: 0;
    margin-left: 10px;
}

@media (max-width: 600px){
    .menu-title{
        font-size: 12px;
        padding: 10px;
    }


    .menu-title p{
        margin: 0;
    }

    .contact-div{
        width: 100vw;
    }

    .contact-part-icon{
        font-size: 18px;
    }

    .contact-part-div {
        margin: 10px;
    }

    .contact-part-div p{
        font-size: 14px;
    }

    .contact-part-title{
        font-size: 18px;
    }


    .contact-part-div a{
        font-size: 14px;
    }

    .image-div{
        width: calc((80vw - 40px)/2);
    }
    
    .image-div img{
        width: calc((80vw - 40px)/2);
    }

    .expo-item{
        width: calc((80vw - 1vw - 25px) / 2);
    }

    .expo-item-image{
        width: calc((80vw - 9vw)/2);
    }
    
    .expo-title{
        font-size: 13px;
    }
}





