.history-div{
    width: 80vw;
    margin-bottom: 20px;
}

.history-category{
    width: fit-content;
    padding-right: 30px;
    color: #2c507b;
    font-size: 20px;
    border-bottom: 1px solid #2c507b;
}

.history-info{
    display: flex;
}

.children{
    font-size: 17px;
    text-align: justify;
    width: 60vw;
    margin-right: 20px;
}