.main-museum{
    width: 100%;
    display: flex;
    justify-content: center;
}

.nav-div{
    display: flex;
    width: 100vw;
    font-size: 20px;
}

.nav-div div{
    width: 50%;
    border: 1px solid gray ;
    text-align: center;
    padding: 10px 0;
}

.active{
    background: #2c507b;
    color: white
}

.booking-list{
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    height: calc(100vh - 100px);
    overflow: auto;
}

.booking-list::-webkit-scrollbar{
    display: none;
}

.booked-info{
    width: 40vw;
    margin: 15px 0;
    margin-left: 5vw;
}

.booked-div{
    display: flex;
    padding-left: 15px;
}

.booked-info-div{
    width: 80%;
    margin: 1vh;
    background-color: white;
    padding: 15px;
    border: 1px solid rgba(168, 167, 167, 0.692);
    box-shadow: 5px 5px 5px rgba(168, 167, 167, 0.692);
    border-radius: 20px;
}

.came{
    background-color: rgba(0, 255, 13, 0.247);
}

.title{
    width: 20vw;
}

.icons{
    width: 100%;
    display: flex;
    justify-content: end;
    background: transparent;
    border: none;
    font-size: 18px;
}

.check{
    color: rgb(49, 201, 49);
    cursor: pointer;
}

.remove{
    color: rgba(146, 35, 35, 0.63);
    margin-left: 10px;
    cursor: pointer;
}

.search-div{
    width: 98vw;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: end;
}

.edit{
    cursor: pointer;
}

.about-div{
    width: 80vw;
    margin-left: 10vw;
    height: calc(100vh - 40px);
    padding: 20px;
    overflow: auto;
}