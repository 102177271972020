.loading-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: loading 2s infinite;
    overflow: hidden;
}

@keyframes loading {
    0%{
        transform: scale(1)
    }
    50%{
        transform: scale(1.2);
    }
    100%{
        transform: scale(1);
    }
}