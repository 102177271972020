.coworkers-list{
    display: flex;
    width: 80vw;
}

.coworkers-list-div{
    width: 60vw;
}

.coworkers-category{
    width: fit-content;
    padding-right: 30px;
    color: #2c507b;
    font-size: 20px;
    border-bottom: 1px solid #2c507b;
}

.each-coworker-div{
    width: 58vw;
    margin: 15px 2vw 15px 0;
}

.coworker{
    display: flex;
    font-size: 16px;
}

.coworker-media-info{
    text-align: center;
    align-self: center;
    width: 15vw;
}

.coworker-image{
    width: 5vw;
    border-radius: 15px;
}

.coworker-main-info{
    max-width: 42vw;
}

.coworker-main-info li{
    margin-bottom: 10px;
}
