.main-admin{
    width: 100vw;
    display: flex;
}

.sidenav-main{
    border-right: 1px solid #2c507b4f;
    width: 20vw;
    height: 100vh;
    overflow: auto;
    box-shadow: 5px 0 5px rgba(168, 167, 167, 0.692);
}

.sidenav-title{
    color: #2c507b;
    font-size: 18px;
    font-weight: bolder;
    display: flex;
    justify-content: center;
}

.menu-full-item-div p{
    margin: 0;
    color: #2c507b
}

.menu-item-name-div{
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid #2c507b4f;
}

.underCategory-items{
    margin-left: 40px
}


.menu-item-name-div:hover{
    background: rgba(168, 167, 167, 0.14);
    cursor: pointer;
}

.messages-list{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
}

.message-div{
    width: 80%;
    margin: 1vh;
    background-color: white;
    padding: 15px;
    border: 1px solid rgba(168, 167, 167, 0.692);
    box-shadow: 5px 5px 5px rgba(168, 167, 167, 0.692);
    border-radius: 20px;
}

.read{
    background-color: rgba(0, 255, 13, 0.247);
}

.message-from{
    display: flex;
    justify-content:space-around;
    border-bottom: 1px solid #2c507b;
}

.message-from p{
    margin-right: 4vw;
}

.message{
    width: 100%;
    text-align: center;
    margin-top: 10px;
}

.icons{
    width: 100%;
    display: flex;
    justify-content: end;
    background: transparent;
    border: none;
    font-size: 18px;
}

.check{
    color: rgb(49, 201, 49);
    cursor: pointer;
}

.remove{
    color: rgba(146, 35, 35, 0.63);
    margin-left: 10px;
    cursor: pointer;
}

.edit{
    cursor: pointer;
}

.element-div{
    width: 100%;
    height: 100vh;
    overflow: auto;
}

.element-div::-webkit-scrollbar{
    display: none;
}

.elements{
    width: 100%;
    height: calc(100vh - 72px);
    overflow: auto;
}

.elements::-webkit-scrollbar{
    display: none;
}

.rent-hole-div{
    width: 100%;
    height: calc(100vh - 72px);
    overflow: auto;
}

.rent-div::-webkit-scrollbar{
    display: none;
}

.element{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #2c507b;
    width: 96%;
    margin: 10px 2%;
    border-bottom: 1px solid #2c507b4f;
}

.element-images-div{
    width: 80vw;
    height: calc(100vh - 120px);
    display: flex;
    flex-wrap: wrap ;
    padding: 10px;
    overflow: auto;
}

.element-image-div{
    width: calc(80vw/3 - 60px);
    margin: 10px;
}

.element-image{
    width: calc(80vw/3 - 60px);
}

.history{
    width: 80vw;
    height: calc(100vh - 40px);
    padding: 20px;
    overflow: auto;
}

.btn-search{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn{
    margin: 15px;
    font-size: 18px;
    padding: 5px 20px;
    background-color: #2c507b;
    border: none;
    border-radius: 10px;
    color: white
}

.search-div{
    display: flex;
    align-items: center;
    margin-right: 15px;
    color: #2c507b
}

.search-input{
    border: none;
    border-bottom:1px solid #2c507b4f;
    background: transparent;
    outline: none;
}

.rent-div{
    display: flex;
    align-items: center;
}

.rent-image{
    height: 8vh;
    margin: 5px;
}