.home-div{
    position: relative;
    display: flex;
    width: 98vw;
    margin-left: 1vw;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    height: calc(50vw / 1.55);
    overflow: hidden;
    background-color: #162335 ;
}

.fon-image{
    width: 100vw;
    height: auto;
}

.name-h{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: justify;
    color: white;
    font-size: 20px;
    width: calc(50vw- 60px);
    height: calc(50vw / 1.55);;
    margin: 0 30px 0 30px ;
}

.name-h h3{
    margin: 0;
    font-weight: 500;
    margin-bottom: 10px;
}

.name-h h3:first-child{
    padding-top: 100px;
}

.logo-image{
    width: 100px;
}

.logo-image-div{
    background-color: white;
    width: fit-content;
    height: fit-content;
    border-radius: 50%;
    padding: 15px;
    margin-right: 15px;
}

.on-animation{
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 5;
}

.name-h div{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.image-back{
    width: 50%;
    height: fit-content;
}

.image-back img{
    width: 50vw;
}

.offers{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
}

.offers-list{
    display: flex;
}

.offers h2{
    margin-bottom: 40px;
}

.offers-list p{
    font-size: 18px;
    padding: 0 15px;
    margin: 5px;
}

.offers-each:last-child{
    border-right: none;
}

.offers-each{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid grey;
    width: calc(90vw / 3);
}