.job-main{
    width: 80vw;
    display: flex;
}

.job-list{
    width: 58vw;
    margin-right: 2vw;
    margin-top: 20px;
}

.job-item{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
    color: #2c507b;
    font-size: 16px;
    border-bottom: 1px solid #2c507b75;
    cursor: pointer;
}

.job-item p{
    margin: 0
}

.job-item:hover{
    background: rgba(168, 167, 167, 0.14)
}

.job-item:last-child{
    border-bottom: none;
}

.job-category{
    width: fit-content;
    padding-right: 30px;
    margin: 0;
    margin-bottom: 15px;
    color: #2c507b;
    font-size: 20px;
    border-bottom: 1px solid #2c507b;

}

.see-job{
    color: white;
    background-color: #2c507b;
    padding: 12px 10px;
    border-radius: 10px;
}

.job-page-main{
    width: 80vw;
    display: flex;
}

.job-page-info{
    width: 58vw;
    margin-right: 2vw;
    margin-top: 15px;
}