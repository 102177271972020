.editor{
    width: calc(100% - 40px);
    height: 70vh;
    display: flex;
    margin: 15px 0;
    padding: 15px;
    background-color: white;
    border: 1px solid rgba(168, 167, 167, 0.692);
    box-shadow: 5px 5px 5px rgba(168, 167, 167, 0.692);
    border-radius: 20px;
}

.rdw-editor-toolbar{
    max-height: 205px;
}

.rdw-editor-main{
    height: calc(70vh - 205px);
}

.rdw-editor-main::-webkit-scrollbar{
    display: none;
}