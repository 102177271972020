.rent-main{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.promo-div{
    position: relative;
    width: 100vw;
    background: black;
    display: flex;
    justify-content: center;
}

.promo-gradient{
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(transparent, black 90%)
}

.promo-video{
    width: 100vw;
}

.promo-video::-webkit-media-controls {
    display: none !important;
}

.rent-places-list{
    margin: 15px 0;
    width: 80vw;
    display: flex;
    flex-wrap: wrap;
}

.rent-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc((80vw - 7vw - 25px)/4);
    margin-right: 1vw;
    margin-bottom: 1vw;
    padding: 5px 0;
    background-color: white;
    border: 1px solid rgba(168, 167, 167, 0.692);
    box-shadow: 5px 5px 5px rgba(168, 167, 167, 0.692);
    border-radius: 20px;
    cursor: pointer;
    transform: scale(1);
    transition: transform ease-in 0.2s;
}

.rent-item:hover{
    transform: scale(1.03);
    transition: transform ease-in 0.2s;
}

.rent-item-image{
    width: calc((80vw - 11vw)/4);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.rent-title{
    font-size: 16px;
    font-weight: bolder;
    margin: 10px 0
}

.parent-div{
    position: relative;
    width: 40vw;
    height: fit-content;
}

.image-slide-main{
    width: 40vw;
    overflow-x: auto;
    overflow-y: hidden;
}

.image-slide-main::-webkit-scrollbar{
    display: none;
}

.image-div{
    width: fit-content;
    display: flex;
    align-items: center;
}

.image-div img{
    width: 40vw;
}

.control-div{
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.control-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1vw;
    width: 3vw;
    height: 3vw;
    background-color: rgba(105, 101, 101, 0.527);
    border-radius: 50%;
    cursor: pointer;
    transform: scale(1);
    transition: transform ease-in 0.3s;
    color: rgb(82, 80, 80);
}

.control-btn:hover{
    transform: scale(1.05);
    transition: transform ease-in-out 0.3s;
}

.control-icon{
    font-size: 2vw;
}

.rent-item-main{
    width: 80vw;
    margin: 15px 0;
}

.rent-item-div{
    display: flex;
}

.rent-item-information{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 38vw;
    margin-left: 2vw
}

.each-info{
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    margin: 15px 0;
    border-bottom: 1px solid #2c507b69;
 }

.each-info p{
    margin: 0
}

.other-info{
    margin-top: 10px;
}

.other-title{
    font-size: 16px;
    font-weight: bolder;
}

.text{
    width: 650px;
    position: absolute;
    font-weight: bold;
    font-size: 56px;
    color: white;
    top: 20vh;
    border-bottom: 1px solid white;
    animation: textanimation 56s infinite;
}

@keyframes textanimation {
    0% {
        right: -1110px;
    }

    2%{
        right: -1110px;
    }

    4%{
        right: 2vw;
    }
    98%{
        right: 2vw;
    }
    100%{
        right: -1110px;
    }
}