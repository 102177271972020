.edit-history{
    width: 76vw;
    height: 100vh;
    margin: 15px 2vw;
    overflow: auto;
}

.btn-div{
    display: flex;
    width: 100%;
    margin-right: 5vw;
    justify-content: end;
    margin-bottom: 15px;
}

.btn{
    width: 15vw;
    font-size: 18px;
    padding: 10px;
    color: white;
    border: none;
    cursor: pointer;
    margin-bottom: 20px;
}

.btn:disabled{
    cursor: auto;
}

.confirm{
    background-color: rgba(17, 155, 17, 0.678);
    border-radius: 10px 0 0 10px;
}

.confirm:disabled{
    background-color: gray;
}

.cancel{
    background-color: rgba(155, 17, 17, 0.678);
    border-radius: 0 10px 10px 0;
}

.file-upload{
    color: #2c507b;
    display: flex;
    align-items: center;
}

.file-upload button{
    background: #3dbb1d9d;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    margin-left: 15px;
}

.file-upload label{
    font-size: 25px;
}

.upload-images-div{
    display: flex;
    overflow: auto;
}

.images-div{
    display: flex;
    width: fit-content;
    margin-top: 54px;
    margin-left: 3vw;
}

.images-div img{
    width: 8vw;
    margin-right: 10px;
    cursor: pointer;
}

.edit-part{
    width: 76vw;
    height: 100vh;
    margin: 15px 2vw;
    overflow: auto;
}

.edit-part::-webkit-scrollbar{
    display: none;
}

.edit-part input{
    background: transparent;
    border: none;
    border-bottom: 1px solid rgba(44, 80, 123, 0.7);
    outline: none;
}

.file-upload-text{
    border: 1px solid #F1F1F1;
    padding: 5px;
    min-width: 25px;
    height: fit-content;
    border-radius: 2px;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
}

.files-div-text{
    background: rgba(128, 128, 128, 0.521);
    border-radius: 10px;
    padding: 5px;
    margin: 5px
}

.files-div{
    min-height: 35px;
    max-height: 105px;
    cursor: copy;
    overflow: auto;
}

.file-upload-text textarea{
    margin: 0;
    margin-left: 10px;
}