.time--title{
    display: none;
}

.react-calendar-datetime-picker .header{
    background: #2c507b;
}

.react-calendar-datetime-picker .daysList .is-selected-day {
    background-color: #3e689c;
}

.react-calendar-datetime-picker .daysList .is-today{
    box-shadow: inset 0 0 0 2px #2c507b
}

.react-calendar-datetime-picker .input-picker .input-picker--input{
    padding: 0;
    background: transparent;
    outline: none;
    border: none;
    margin: 10px 0;
    border-bottom: 1px solid #2c507b;
    border-radius: 10px;
    color: #2c507b;
}

.react-calendar-datetime-picker{
    width: 50vw;
}