.contact-main{
    width: 80vw;
    display: flex;
}

.contact-info{
    width: 58vw;
    display: flex;
    margin-right: 2vw;
    margin-top: 20px;
    height: fit-content;
}

.contact-category{
    width: fit-content;
    margin-top: 0;
    padding-right: 30px;
    color: #2c507b;
    font-size: 20px;
    border-bottom: 1px solid #2c507b;
}

.link-item{
    color: black;
    text-decoration: none;
    margin: 10px 0
}

.flex-items{
    display: flex;
    align-items: center;
}

.contact-part p{
    margin: 10px 0;
    margin-left: 10px;
}

.contact-us{
    width: fit-content;
}

.map-main{
    width: fit-content;
}

.map{
    width: 30vw;
    height: calc(30vw/1.6);
    margin-right: 2vw;
}

.online-contact{
    width: 58vw;
    display: flex;
    justify-content: center;
}

.online-contact-form{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.online-contact-form input, textarea{
        width: 100%;
        font-size: 14px;
        margin-bottom: 2vh;
        background: transparent;
        border: none;
        border-bottom: 1px solid #2c507b75;
        outline: none;
        padding: 5px 10px;
}

.online-contact-form button{
    width: 100%;
    margin-top: 1vh;
    font-size: 18px;
    background-color: #2c507b;
    color: white;
    border: none;
    padding: 10px 0;
    border-radius: 10px;
    cursor: pointer;
}

.online-contact-error{
    width: 100%;
    text-align: center;
    color: rgba(221, 28, 28, 0.877);
    font-size: 14px;
    margin-top: 10px;
}
